@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";
.leftBox {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 508px;
  margin: auto;
  @media screen and (max-width: 1280px) and (min-width: 900px) {
    padding-right: 18px;
  }
  @media screen and (max-width: 992px) {
    padding-top: 80px;
    padding-bottom: 40px;
    min-height: 100%;
  }
  h2 {
    @include heading_h2;
    span {
      color: $primary-color;
    }
  }
  h3 {
    font-family: "poppins-light";
    font-style: normal;

    margin-top: 40px;
    font-size: 20px;
    line-height: 27px;
    /* or 135% */

    text-align: center;

    color: #828181;
  }
  .formsBox {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 31px;
    max-width: 350px;
    flex-direction: column;
    .formInput {
      min-width: 350px;
      margin-top: 19px;
      position: relative;
      label {
        font-family: "poppins-light";
        font-style: normal;

        font-size: 15px;
        line-height: 22px;
        /* identical to box height */
        display: block;

        letter-spacing: 0.025em;

        color: $black-color;
        margin-bottom: 4px;
      }
      input {
        background: $white-color;
        border: 1px solid #afafaf;
        border-radius: 3px;
        width: 100%;
        min-height: 50px;
        font-family: "poppins-light";
        font-style: normal;

        font-size: 12px;
        line-height: 18px;
        /* identical to box height */
        padding: 17px 33px;
        letter-spacing: 0.025em;

        color: $grey-color;
        &:focus-visible {
          outline: $primary-color auto 1px;
        }
        &::placeholder {
          font-family: "poppins-light";
          font-style: normal;

          font-size: 12px;
          line-height: 18px;
          /* identical to box height */

          letter-spacing: 0.025em;

          color: $grey-color;
        }
      }
      .showPass {
        position: absolute;
        right: 16px;
        top: 45px;
        cursor: pointer;
        svg {
          color: $primary-color;
        }
      }
    }
    .forget {
      max-width: 127px;
      margin-left: auto;
      margin-top: 18px;
      cursor: pointer;
      a {
        font-family: "poppins-bold";
        font-style: normal;

        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: $grey-color;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 23px;
      h5 {
        font-family: "poppins-regular";
        font-style: normal;

        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.025em;
        color: $grey-color;
      }
      a {
        margin-left: 27px;
        font-family: "poppins-bold";
        font-style: normal;

        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.025em;
        text-decoration-line: underline;

        color: $primary-color;
      }
    }
    .button {
      min-width: 132px;
      max-width: 132px;
      margin: auto;
      button {
        background: $primary-color;
        margin-top: 20px;
        border-radius: 3px;
        min-height: 41px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "poppins-semi-bold";
        font-style: normal;

        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.025em;

        color: $white-color;
      }
    }
  }
  .parentInput {
    position: relative;
    span {
      position: absolute;
      left: 3px;
      display: block;
      color: $red-color;
      font-family: "poppins-semi-bold";
      font-size: 12px;
    }
  }
}
