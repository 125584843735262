@import "../../styles/theme/variables";
@import "../../styles/theme/mixins";

.loginBox {
  max-width: 600px;
  margin: 0 auto;
}

.loginBox h1 {
  font-family: "poppins-bold";
  font-style: normal;
  font-size: 28px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  margin-bottom: 30px;
}

.form_box {
  padding: 30px;
  border: 1px solid rgb(229, 229, 229);

  @media (max-width: 640px) {
    padding: 16px;
  }
}

.form_box h5 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 15px 0;
}

.form_box .col-12 {
  padding: 0;
}

.image_none label {
  display: flex !important;
  border: 1px solid #dfdfdf;
  padding: 10px 15px;
  margin: 0 0 15px 0 !important;
  border-radius: 3px;
  text-transform: capitalize;
}

.signer_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;

  @media (max-width: 640px) {
    flex-wrap: wrap;
    gap: 12px;
  }
}

.signer_title button {
  background: #00cfc5 !important;
  padding: 7px 18px;
  color: #fff;
}

.signer_title button:hover {
  background-color: #000;
}

.signer_title button .hvr-icon {
  display: none;
}

.signer_title button img {
  filter: brightness(100);
  margin-right: 5px;
}

.signer_title h5 {
  margin: 0;
}

.bottomButton {
  margin-top: 20px;
}

.bottomButton button {
  background-color: #00cfc5;
  color: #fff;
  font-family: inherit;
}

.bottomButton button:hover {
  background-color: #000;
}

.form_main_box {
  margin-top: 0;
}

.form_main_box {
  position: relative;
}

.position_minus {
  position: absolute;
  top: 0;
  right: 0;
}

.position_minus img {
  filter: brightness(0);
}

.form_main_box label {
  font-size: 14px;
  font-weight: 500;
  display: block;
  width: 100%;
}

.form_main_box input {
  padding: 10px;
  height: 42px;
  border: 1px solid #dfdfdf;
  width: 100%;
}

 .formCheckPhone {
  border: 1px solid #dfdfdf;
  padding: 0px 0.5rem;
  input {
    border: none;
    padding: 10px 0.5rem;
  }
 }

.form_main_box form {
  margin-bottom: 10px;
}

.pdf_format {
  display: flex;
  flex-wrap: wrap;
}

.pdf_format>div:first-child {
  width: 21%;
  padding: 0 6px;

  @media (max-width: 1199px) {
    width: 100%;
    margin-bottom: 12px;
  }
}

.pdf_format .dashboard_left_inner {
  position: sticky;
  top: -60px;
  border: 1px solid #e5e5e5;
  padding: 6px;
  height: calc(100vh - 230px);
  // height: calc(100vh - 210px);
  overflow-y: auto;
  min-width: 13rem;
  // width: 1;
}

.pdf_format .pdf_row:first-child {
  background: #f7f7f7;
  padding: 12px;
  border: 0;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
}

.pdf_format .pdf_row {
  display: flex;
  flex-direction: row;
  padding: 12px;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
  border: 1px dashed #e5e5e5;
  margin-bottom: 8px;

  span {
    word-break: break-all;
  }
}

.pdf_format .pdf_row select {
  width: 100%;
  background-color: #fff;
  padding: 10px 10px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}

.pdf_format .pdf_row h6 {
  margin-bottom: 0;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.pdf_format .pdf_row span {
  display: block;
  text-align: center;
  font-size: 14px;
}

.pdf_format .pdf_row img {
  width: 20px;
}

.pdf_format>div:nth-child(2) {
  width: 59%;
  margin: 0;
  padding: 0 6px;

  @media (max-width: 1199px) {
    width: 100%;
    margin-bottom: 12px;
  }
}

.pdf_format>div:last-child {
  padding: 0 6px;
  width: 20%;

  @media (max-width: 1199px) {
    width: 100%;
  }
}

.siderbarPopup_inner {
  position: sticky;
  top: -60px;
  background-color: #f7f7f7;
  padding: 30px;
  // height: calc(100vh - 210px);
  height: calc(100vh - 230px);
  overflow-y: auto;
}

.dashboard_left {
  background-color: #f1f1f1;
  padding: 20px;
  height: 100%;
}

.inner_border_box {
  border: 1px dashed #dfdfdf;
  padding: 15px;
  margin-top: 20px;
  font-size: 14px;
}

.inner_border_box h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.inner_border_box p {
  font-size: 14px;
}

.innerBox {
  display: block;
  border: 1px solid #e5e5e5;
  padding: 8px;
}

.siderbarPopup button {
  background: #00cfc5;
  border: 0;
  color: #fff;
  padding: 10px 18px;
  font-size: 16px;
  font-family: inherit;
  letter-spacing: 0.8px;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  width: 100%;
}

.assigned {
  // margin: 40px 0 0 0;
  border: 1px solid #dfdfdf;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.siderbarPopup div {
  margin-bottom: 15px;
  font-size: 14px;
}

.formChecks h6 {
  margin-bottom: 10px;
}

.formChecks input {
  display: block !important;
}

.formattingTitle h6 {
  margin-bottom: 15px;
  font-weight: 700;
}

.formattingTitle select {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  display: block;
  border: 1px solid #dfdfdf;
  padding: 10px;
  height: 46px;
}

.contentBox label {
  border: 1px dashed #dfdfdf;
  max-width: 600px;
  margin: 0 auto;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
}

.contentBox button {
  background-color: #00cfc5;
  border: 0;
  color: #fff;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 5px;
  display: block;
  margin: 30px auto 0 auto;
}

.contentBox button:hover {
  background-color: #000;
}

.levelOne {
  display: flex;
  align-items: center;
}

.levelOne span {
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;
}

.contentBox div {
  text-align: center;
  margin-top: 15px;
}

.optionContainer {
  display: block;
}

.askPasswordButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  text-align: center;
  margin: auto;

  @media (max-width: 767px) {
    width: 80% !important;
  }
}