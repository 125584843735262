.customBox {
  table {
    box-shadow: none;
  }
  .contentBox {
    td {
      // font-family: "poppins-semi-bold";
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      box-shadow: none;
      /* identical to box height, or 200% */

      color: #6c6a6a;
    }
  }
  .headingBox {
    th {
      font-family: "poppins-semi-bold";
      font-style: normal;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 215% */

      letter-spacing: 0.2px;
      text-transform: capitalize;

      color: #92929d;
    }
  }
}
