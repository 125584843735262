@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";
.mainHead {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .content {
    h2 {
      @include heading_h2;
      margin-bottom: 50px;
      /* identical to box height, or 99% */

      span {
        color: $primary-color;
      }
    }
    .buttonPrev {
      @include prev_button;
    }
    .buttonNext {
      @include next_button;
    }
  }
  @media screen and (max-width: 640px) {
    justify-content: flex-start;
    padding-top: 40px;
  }
  .boxParent {
    display: flex;
    align-items: center;
    justify-content: center;
    .boxStep {
      min-width: 28px;
      min-height: 28px;
      border-radius: 50%;

      border: 4px solid $primary-color;
    }
    &.active {
      .boxStep {
        position: relative;
        padding: 2px;
        &::after {
          content: "";
          background-color: $primary-color;
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
        }
      }
    }
  }
}
