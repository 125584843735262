
.logo_head{
    font-family: "poppins-bold" !important;
    font-style: normal !important;
    font-size: 28px !important;
    line-height: 24px !important;
    color: #00CFC5 !important;
    cursor: pointer;
}
.nav_link_home{
    padding: 0;
    padding-left: 0px;
    font-weight: 400;
    color: black;
}
.nav_link_home:hover{
    text-decoration: none;
    color: #00CFC5;
    transition: 0.5s ease;
}
.sign_in_btn{
    color: #00CFC5 !important;
}
.sign_up_btns{
    
    color: #00CFC5 !important;
    margin-right: 5px !important;
}
.req_dem_btn{
    /* padding-left: 8px; */
    background-color: #00CFC5 !important;
}
.req_dem_btn:hover{
    background-color: #00CFC5 !important;
}

@media (max-width: 1100px){
    .nav_link_home button {
        padding: 0 10px;
        min-width: inherit;
    }

    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.css-1osj8n2-MuiGrid-root {
        flex-basis: 43%;
        max-width: 43%;
    }

    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4.css-2hfwlx-MuiGrid-root {
        flex-basis: 40.333%;
        max-width: 40.333%;
      }

      .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.req_dem_btn.css-xegqiv-MuiButtonBase-root-MuiButton-root {
        padding: 5px 15px;
      } 
}