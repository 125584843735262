@import "../../../../styles/theme/variables";
@import "../../../../styles/theme/mixins";

.actionsButtons {
  display: flex;
  justify-content: flex-end;
  gap: 6px;

  button {
    border: 1px solid #00cfc5;
    padding: 6px;
    width: 30px;
    height: 30px;
    min-width: auto;
  }
}

.gridBox {
  background: #edebeb;
  .gridRow {
    padding-right: 1em;
    padding-bottom: 1em;
    margin: 0 0 16px !important;
    width: 100% !important;
  }
  > .actionsButtons {
    margin: 0 16px 0 0;
    padding-top: 10px;
    .editbtn {
      display: none;
    }
  }
}

.tableBlock {
  overflow-y: hidden;
  white-space: nowrap;
}

.inputBox {
  border: 1px dashed #e7e7e7;
  padding: 6px;
  margin-bottom: 6px;
  @media (max-width: 640px) {
    padding: 0;
  }
  label {
    display: block;
  }
  input,
  select,
  textarea {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 8px;
    pointer-events: none;
    color: #000;
    &:focus,
    &:focus-visible {
      border-color: #00cfc5;
      outline: 0;
    }
  }
  input {
    &::placeholder {
      color: #cdcdcd;
    }
  }
  select {
    appearance: none;
    position: relative;
    background: url(../../../../assets/sidebar/activemenu.svg) no-repeat;
    background-position: 99%;
    background-size: 16px;
    padding-right: 32px;
  }
  .checkboxgroup {
    > label {
      margin-right: 24px;
      margin-bottom: 8px !important;
    }
    label {
      margin-bottom: 0;
      min-height: 16px;
    }
    .customCheckBox {
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 8px;
    }
  }
  .fileUpload {
    display: inline-flex;
    max-width: 100% !important;
    padding: 9px 12px;
    min-width: auto !important;
    align-items: center;
    background: #00cfc5;
    color: #fff;
    pointer-events: none;

    input {
      width: auto;
      border: 0;
      order: 3;
      padding: 0;
      &::-webkit-file-upload-button {
        display: none;
      }
    }
    cursor: pointer;
    label {
      margin: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        order: 1;
        margin-right: 8px;
      }
      span {
        order: 2;
        margin-right: 10px;
      }
    }
  }
  .ratingBox {
    label {
      margin-bottom: 0;
      padding-right: 16px;
      cursor: pointer;
    }
    input {
      display: none;
    }
    display: flex;
    align-items: center;
    pointer-events: none;
  }
  .autoComplete {
    input {
      border: 0;

      &::placeholder {
        color: #787878;
      }
    }
  }
  .buttonGroup {
    display: flex;
    align-items: center;
    gap: 16px;
    .button {
      max-width: 100%;
      svg {
        margin-right: 5px;
      }
      button {
        text-transform: capitalize;
        margin-top: 0;
        font-family: "poppins-regular";
        background-color: #00cfc5;
        color: white;
        border-radius: 0;
        padding: 8px 12px;
      }
    }
  }
  .imgBox {
    max-width: 130px;
    // border: 1px solid #e5e5e5;
    border: 1px solid #000000;
    img {
      max-width: 100%;
    }
  }
  .imgFieldBox {
    border: 1px solid #000000;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border: 1px solid #000000;
      width: 100%;
      // object-fit: cover;
      object-fit: contain;
      height: inherit;
      padding: 0.2rem;
    }
  }
  .textboxNote {
    font-size: 11px;
    color: #606060;
    span {
      color: red;
    }
  }
}

.recruiterInfoField {
  pointer-events: all !important;
}

.col_item {
  padding: 16px;
  border: "1px solid #e5e5e5";
  width: "100%";
  padding: "16px";
  font-size: "1rem";
}

.previewForm {
  input,
  select,
  textarea,
  .ratingBox,
  .fileUpload {
    pointer-events: all;
  }
  .col_item {
    padding: 0;
  }
  .gridBox {
    background: transparent;
    .inputBox {
      border: 0;
      padding: 0;
    }
    > div > div > div {
      border: 0 !important;
      > p.dropText {
        display: none;
      }
    }
  }
  > div {
    border: 0;
    .actionsButtons {
      display: none;
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    border: 1px solid #e5e5e5;
    padding: 8px;
  }
  th {
    background: #f1f1f1;
  }
}
