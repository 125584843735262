.pdfBg {
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #123;
  display: flex;
  position: absolute;
}
