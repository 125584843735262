@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";

.priceBox {
  padding: 24px;
  // border: 1px solid $primary-color;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    h4 {
      @include main-heading;
    }
  }
  .desc {
    h5 {
      font-family: "poppins-semi-bold";
      font-style: normal;

      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 160% */

      color: $black-color;
      margin: 0;
      margin-bottom: 12px;
    }
    p {
      font-family: "poppins-regular";
      font-style: normal;
      //   font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 200% */

      color: $black-color;
    }
  }
  .buttonSection {
    .button {
      margin: 0;
      margin-bottom: 16px;
    }
    p {
      font-family: "poppins-regular";
      font-style: normal;
      //   font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      /* or 150% */

      color: #898989;
      max-width: 464px;
      margin-bottom: 12px;
    }
    h5 {
      font-family: "poppins-regular";
      font-style: normal;
      //   font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      /* identical to box height, or 200% */

      color: $black-color;
    }
  }
  .content {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    max-width: 500px;
    flex-direction: column;

    border-image-source: linear-gradient(
      180deg,
      #afafaf 0%,
      rgba(175, 175, 175, 0) 100%
    );

    .heading {
      font-family: "poppins-semi-bold";
      font-style: normal;

      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 160% */

      color: $black-color;
      margin: 0;
      margin-bottom: 12px;
    }
    .contentBox {
      display: flex;
      align-items: center;

      margin-bottom: 10px;
      width: 100%;

      flex-wrap: wrap;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }
      span {
        font-family: "poppins-regular";
        font-style: normal;
        //   font-weight: 500;
        font-size: 15px;
        cursor: pointer;
        line-height: 24px;
        /* identical to box height, or 200% */

        color: $black-color;

        margin-left: 10px;
        opacity: 0.9;
        &:hover {
          opacity: 1;
        }
      }
      .left {
        flex: 0 0 55%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 600px) {
          flex-wrap: wrap;
          flex: 0 0 100%;
          gap: 10px;
          align-items: flex-start;
        }
        .button {
          margin: 0;
          max-width: 226px;
          min-width: 226px;
          @media screen and (max-width: 992px) {
            max-width: 100%;
            min-width: 100%;
            display: flex;
            align-items: center;
          }
          button {
            margin: 0;
            min-height: 30px;
            max-width: 100px;
            font-size: 11px;
            margin-left: auto;
            @media screen and (max-width: 992px) {
              margin-left: 0;
            }
          }
        }
        .withSelect {
          display: flex;
          align-items: center;
          .checkbox {
            border: 1px solid $primary-color;
            min-width: 26px;
            max-width: 26px;
            // width: 100%;
            min-height: 26px;
            border-radius: 50%;
            cursor: pointer;
          }
          span {
            font-family: "poppins-regular";
            font-style: normal;
            //   font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            /* identical to box height, or 200% */

            color: $black-color;

            margin-left: 10px;
          }
        }
        // .checkbox {
        //   border: 1px solid $primary-color;
        //   min-width: 26px;
        //   max-width: 26px;
        //   // width: 100%;
        //   min-height: 26px;
        //   border-radius: 50%;
        //   cursor: pointer;
        // }
        span {
          font-family: "poppins-regular";
          font-style: normal;
          //   font-weight: 500;
          font-size: 15px;
          line-height: 24px;
          /* identical to box height, or 200% */

          color: $black-color;

          margin-left: 10px;
        }
        label {
          margin: 0;
          font-family: "poppins-regular";
          font-style: normal;
          //   font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 200% */

          color: $black-color;
        }
        .selectParent {
          margin-left: 10px;
          .selectChild {
            margin: 0;
          }
        }
      }

      .right {
        flex: 0 0 45%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media screen and (max-width: 600px) {
          margin-top: 8px;
          flex: 0 0 100%;
        }
        p {
          margin: 0;
          font-family: "poppins-regular";
          font-style: normal;

          font-size: 12px;
          line-height: 15px;

          color: #000000;
        }
        .inputBox {
          min-width: auto;
          margin: 0;
          input {
            min-width: 30px;
            max-width: 30px;
            min-height: 30px;
            max-height: 30px;
            padding: 5px !important;
          }
        }
        span {
          font-family: "poppins-regular";
          font-style: normal;
          //   font-weight: 500;
          font-size: 11px;
          line-height: 24px;
          /* identical to box height, or 200% */

          color: #898989;
          margin-left: 6px;
        }
      }
    }
  }
  .featured {
    .header {
      text-align: center;
      justify-content: center;
    }
    .card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 130px;
      background: $white-color;
      box-shadow: 0px 4px 7px -2px rgba(0, 0, 0, 0.25);
      border-radius: 7px;
      cursor: auto;
      img {
        max-width: 42px;
        max-height: 42px;
        margin-bottom: 10px;
      }
      h5 {
        font-family: "poppins-regular";
        font-style: normal;
        //   font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 200% */

        color: $black-color;
      }
    }
  }
}

.cont {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  margin-left: 0 !important;
  left: 0;
  height: 25px;
  border: 1px solid $primary-color;
  border-radius: 50%;
  width: 25px;
  background-color: transparent;
}

/* On mouse-over, add a grey background color */
.cont:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.cont input:checked ~ .checkmark {
  background-color: $primary-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cont .checkmark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// radio button
.cont2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px !important;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 5px;
}

/* Hide the browser's default radio button */
.cont2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid $primary-color;
  background-color: transparent;
  border-radius: 50%;
  margin-left: 0 !important;
}

/* On mouse-over, add a grey background color */
// .cont2:hover input ~ .checkmark2 {
// }

/* When the radio button is checked, add a blue background */
.cont2 input:checked ~ .checkmark2 {
  background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.cont2 input:checked ~ .checkmark2:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.cont2 .checkmark2:after {
  top: 50.5%;
  left: 50.4%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;

  border-radius: 50%;
  background: $primary-color;
}

.sellBox {
  min-height: 380px;
  padding: 12px;
  border: 1px solid $primary-color;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  .box1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    .heading {
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      .headbox {
        display: flex;
        align-items: center;
        .headbox1 {
          flex: 0 0 50%;

          font-family: "poppins-semi-bold";
          font-style: normal;

          font-size: 12px;
          line-height: 15px;
          /* identical to box height, or 107% */

          color: #000000;
        }
        .headbox2 {
          flex: 0 0 16.66%;
          font-family: "poppins-semi-bold";
          font-style: normal;

          font-size: 12px;
          line-height: 15px;
          text-align: center;
          /* identical to box height, or 107% */

          color: #000000;
        }
        .headbox3 {
          flex: 0 0 50%;
          font-family: "poppins-light";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 15px;
          /* or 150% */

          color: #000;
          margin-bottom: 6px;
        }
        .headbox4 {
          flex: 0 0 16.66%;
          font-family: "poppins-semi-bold";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #626262;
          text-align: center;
          /* or 150% */

          // color: $black-color;
        }
      }
    }

    h5 {
      font-family: "poppins-regular";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      color: $black-color;
    }
    h6 {
      font-family: "poppins-light";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: $black-color;
    }
  }
  .butonsell {
    min-width: auto;
    max-width: 100%;
  }
}
