@import "../../styles/theme/variables";
@import "../../styles/theme/mixins";

.heading {
  margin-top: 1rem;
  .heading_sticky {
    position: fixed;
    background-color: #ffffff;
    padding: 1rem !important;
  }
  .heading_buttons_div {
    display: flex;
    align-items: flex-end;
    .heading_button_primary {
        margin-top: 0 !important;
    }
  }
}
.main_content {
  margin-top: 1rem; 
}
