@import "../../../styles/theme/variables";


.rowMarginTop {
  margin-top: 5rem;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    margin-top: 5rem;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    margin-top: 6rem;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    margin-top: 6rem;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    margin-top: 6.5rem;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    margin-top: 6.5rem;
  }

  .mainHeading {
    color: rgb(0, 207, 197);
  }

  .privacyPageList {
     /* Extra small devices (phones, 600px and down) */
      @media only screen and (max-width: 600px) {
        padding: 0 0.8rem;
      }

      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
        padding: 0 0.8rem;
      }

    li::marker {
      color: rgb(0, 207, 197);
    }
  }

  .privacyParaTag {
    margin-top: 0.7rem;
    text-align: justify;

    .privacyHeading {
      color: rgb(0, 207, 197);
    }
  }
}