@import "../../styles/theme/variables";
.phone-parrent {
  margin-top: 19px;
  &.new-border {
    .PhoneInput {
      border: 2px solid #898989;
      border-radius: 6px;
    }
  }
  // test phone number
  label {
    font-family: "poppins-light";
    font-style: normal;

    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    display: block;

    letter-spacing: 0.025em;

    color: $black-color;
    margin-bottom: 4px;
  }
  .PhoneInput {
    background: $white-color;
    border: 1px solid #afafaf;
    border-radius: 3px;
    width: 100%;
    min-height: 41px;
    font-family: "poppins-light";
    font-style: normal;

    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    padding: 10px 16px;
    letter-spacing: 0.025em;

    color: $black-color;
    input {
      border: none;
      font-family: "poppins-light";
      font-style: normal;

      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      letter-spacing: 0.025em;

      color: $black-color;

      &:focus-visible {
        outline: transparent;
      }
    }
    &.PhoneInput--focus {
      border: 1px solid $primary-color;
    }
    .PhoneInputCountryIcon {
      margin-right: 5px;
    }
    .PhoneInputCountry{
      @media screen and (max-width: 992px) {
        width: 15%;
      }
    }
    .PhoneInputCountrySelectArrow {
      border-color: #1d1929;
      width: 7px;
      height: 7px;
      margin-top: -4px;
      margin-right: 2px;
      opacity: 1;
    }
    .PhoneInputCountrySelect:focus
      + .PhoneInputCountryIcon
      + .PhoneInputCountrySelectArrow {
      opacity: 1;
      color: transparent;
    }

    .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
      box-shadow: none;
    }

    .PhoneInputCountrySelect:focus
      + .PhoneInputCountryIcon
      .PhoneInputInternationalIconGlobe {
      opacity: 1;
      color: transparent;
    }
    .PhoneInputCountryIcon--border {
      box-shadow: none;
    }
  }
}

.indicator-box1 {
  & .MuiStepConnector-line {
    border-top-width: 3px !important;
    border-color: $primary-color !important;
  }
}
.indicator-box2 {
  & .MuiStepConnector-line {
    border-top-width: 3px !important;
    border-color: $primary-color !important;
  }
}
