.blogs_card_first {
  display: flex;
  justify-content: space-between;
}
.blogs_card_main {
  padding: 5px;
  height: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  /* border: 2px solid red; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.blogs_card_main_img {
  width: 100% !important;
  height: auto !important;
}
.blogs_card_first_name_btn {
  font-family: "poppins-bold" !important;
  font-style: normal !important;
  font-size: 17px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
/* .blogs_card_main_data{
    width: 100% !important;
    height:auto !important;
} */
.blogs_article_btn_box {
  display: flex;
  justify-content: center;
}
.blogs_article_btn {
  background-color: #00cfc5 !important;
}
.blogs_cards_box {
  border-radius: 20px 20px 0px 0px;
  /* max-width: 100% !important; */
  width: 100%;
  height: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.blogs_card_first_heading_body1:hover {
  text-decoration: none;
}
.blog_card_parabox {
  /* width: 320px; */
  padding-left: 8px;
}
.blogs_card_first_heading_h5_cards {
  font-family: "poppins-semi-bold" !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.blogs_card_first_heading_body1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px !important;
  line-height: 30px;
  color: #757575;
}
@media only screen and (max-width: 440px) {
  .blogs_card_first_heading_h5,
  .refrencing_heading_bold {
    font-size: 18px !important;
  }
  .blogs_card_first_heading_h5_cards {
    font-size: 16px !important;
  }
  .blogs_card_first_heading_body1,
  .blogs_card_first_name_btn {
    font-size: 14px !important;
  }
  .blogs_card_first_name_btn {
    font-size: 14px !important;
  }
  /* .blogs_cards_box{
        width: 325px;
    } */
}
@media only screen and (max-width: 426px) {
  .blogs_cards_box {
    width: 395px;
  }
}
@media only screen and (max-width: 376px) {
  .blogs_cards_box {
    width: 345px;
  }
}
@media only screen and (max-width: 321px) {
  .blogs_cards_box {
    width: 290px;
  }
}
.detail_box {
  display: flex;
  flex-direction: column;
  height: calc(100% - 244px);
}
