@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";

.mainHead {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 992px) {
    padding: 10px;
  }
  @media screen and (max-width: 640px) {
    justify-content: flex-start;
    padding-top: 40px;
  }
  .content {
    h2 {
      @include heading_h2;
      span {
        color: $primary-color;
      }
    }
    h3 {
      @include heading_signup;
      margin-bottom: 20px;
      margin-top: 20px;
      /* identical to box height */
    }
    p {
      @include description_signup;

      max-width: 509px;

      margin-bottom: 20px;
    }
    .boxparent {
      max-width: 272px;
      margin: auto;

      .parentInput {
        position: relative;

        .input {
          max-width: 272px;
          min-width: 272px;
          margin: auto;

          label {
            font-family: "poppins-regular";
          }
        }
        span {
          display: block;
          position: absolute;
          left: 3px;
          color: $red-color;
          font-family: "poppins-semi-bold";
          font-size: 12px;
        }
      }
    }
  }
  .buttonNext {
    @include prev_button;
    position: absolute;
    @media screen and (max-width: 992px) {
      bottom: 30px !important;
      left: 12px !important;
    }
    
  }
  
}
