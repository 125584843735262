@import "../../../styles/theme/mixins";
@import "../../../styles/theme/fonts";
@import "../../../styles/theme/variables";

.parent {
  margin-top: 100px;

  .main_heading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h4 {
      @include main-heading;
    }
    b {
      @include main-heading;
      color: $primary-color;
      text-decoration: underline;
    }
    h6 {
      padding-top: 15px;
      font-family: "poppins";
      font-size: 16px;
      color: #757575;
    }
  }
  .job_box_main {
    display: flex;
    justify-content: center;
    .job_box {
      margin-top: 30px;
      // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border: 1px solid #e5e5e5;
      width: 100%;
      padding: 20px;
      border-radius: 10px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      .job_box_img {
        display: flex;
        align-items: center;
        padding-right: 24px;
        @media screen and (max-width: 600px) {
          justify-content: center;
        }
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .job_box_right {
        display: flex;
        // justify-content: center;
        flex-direction: column;
        // justify-content: center;
        @media screen and (max-width: 600px) {
          align-items: center;
        }
        .job_box_right_head {
          @media screen and (max-width: 600px) {
            display: flex;
            justify-content: center;
          }
        }
        .job_box_heading {
          margin: 10px 0 15px 0px;

          h5 {
            font-family: "poppins";
            font-weight: 600;
            font-size: 22px;
          }
          span {
            color: $primary-color;
          }
          .job_box_left {
            @media screen and (max-width: 600px) {
              display: flex;
              justify-content: center;
            }
          }
          .job_box_heading_right {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 600px) {
              justify-content: center;
            }
          }
        }
        .job_btn_box {
          .job_btn_box_heading {
            @media screen and (max-width: 600px) {
              display: flex;
              justify-content: center;
            }
          }
          .job_btn_box_heading_left {
            display: flex;
            justify-content: center;
          }
          @media screen and (max-width: 600px) {
            display: flex;
            justify-content: center;
          }
          h6 {
            font-family: "poppins";
            font-weight: 300;
            font-size: 15px;
          }
          .job_btn {
            padding-top: 5px;
            display: flex;
            justify-content: flex-end;
            button {
              text-transform: capitalize;
              color: $white-color !important;
              background-color: $primary-color;
            }
          }
        }
      }
    }
  }
  .jobs_details_left_box {
    h5 {
      font-family: "poppins";
      font-weight: 600;
      padding-bottom: 10px;
      font-size: 24px;
    }
    p {
      font-family: "poppins";
      font-size: 16px;
      color: #757575;
    }
    button {
      margin-top: 25px;
      background-color: $primary-color;
      width: 150px;
      text-transform: capitalize;
      color: $white-color !important;
    }
  }
  .jobs_details_right_box {
    // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    // padding: 20px;
    // border: 1px solid #e5e5e5;
    // border-radius: 10px;
    // height: 600px;
    h2 {
      font-family: "poppins";
      font-weight: 600;
      padding-bottom: 10px;
      font-size: 24px;
      // border-bottom: 2px solid $primary-color;
    }
    p {
      font-family: "poppins";
      font-size: 16px;
      color: #757575;
      span {
        color: $primary-color;
        padding-left: 15px;
      }
    }
    li {
      position: relative;
      padding: 0 0 0 16px;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
      gap: 20px;
      justify-content: space-between;
      span {
        &:nth-child(even) {
          text-align: right;
        }
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #00cfc5;
      }
    }
  }
}
