.bodyView {
  /* background: #f2f2f2 url("https://formbuilder.online/assets/img/noise.png")
    repeat fixed; */
  background-color: #f1f1f1;
  padding: 20px;
  /* max-width: 50%; */
  display: flex;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.bodyView:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.rendered-form .formbuilder-button.form-group .btn-default.btn {
  border: 1px solid gray;
}

/* Form View */
.formView {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  border: 1px solid #00cfc5;
  padding: 20px;
}
@media (max-width: 640px) {
  .formView {
    padding: 12px;
  }
}
