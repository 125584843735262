@import url("./theme/_fonts.scss");
@import "./theme/variables";
@import url("./theme/_styles.scss");

html {
  overflow-y: scroll;
}

button {
  overflow: hidden;

  &:focus {
    outline: 1px dotted;
    outline: 5px transparent !important;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins-regular";
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a,
ol {
  text-decoration: none;
  margin: 0;
  padding: 0;
}

// text-blue
.text-primary {
  color: $primary-color !important;
}

// customer container according to figma

.css-1ss2ei0-MuiContainer-root {
  @media screen and (min-width: 1200px) {
    max-width: 1440px !important;
  }

  @media screen and (min-width: 1536px) {
    max-width: 1536px !important;
  }
}

::-webkit-scrollbar-thumb {
  border: 7px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
  overflow-y: scroll;
  background-color: #00cfc5;
}

::-webkit-scrollbar {
  width: 16px;
}

.stage-wrap {
  border: 3px dashed #ccc;
}

// .cb-wrap.pull-right .form-actions.btn-group {
//   display: none;
// }

.cb-wrap.pull-right .form-actions.btn-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 100%;
}

.cb-wrap.pull-right .form-actions.btn-group .clear-all.btn.btn-danger {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
  background-color: #f1f1f1;
  color: black;
}

.cb-wrap.pull-right .form-actions.btn-group .submitFormBuilder {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
  background-color: $primary-color;
}

.cb-wrap.pull-right .form-actions.btn-group .draftFormBuilder {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
  background-color: $secondary-color;
  color: white;
}

.form-signature {
  font-style: italic;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  width: 100%;
  height: 34px;
  display: block;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  font-weight: bold;
  // text-align: center;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1idn90j-MuiGrid-root span {
  color: red !important;
  font-size: small !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1idn90j-MuiGrid-root span.reason_heading_para {
  color: #757575 !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1idn90j-MuiGrid-root span.resource_learn_heading_para {
  color: #757575 !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1idn90j-MuiGrid-root .time-picker-custom span {
  color: #000 !important;
}

.styles_parentInput__X4Vx-.MuiBox-root.css-0 span {
  color: red !important;
  font-size: small !important;
}

.error-inputfield {
  color: red !important;
  font-size: small !important;
}

.header_box {
  // background-color: #fff;
  // border-bottom: 1px solid #dfdfdf;
  // box-shadow: 0 0 5px #0003;
  left: 0;
  // padding: 13px 118px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8;
}

.right_column {
  background-color: #fff;
  // height: 100vh;
  height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  padding: 60px;
  width: 59%;
}