.imageBox{
    position: relative;
    min-height: 100vh;
    max-height: 100vh;
    img{
        position: absolute;
        right: 0;
        top: 0;
        object-fit:cover;
        width: 100%;
        height: 100%;
    }
}