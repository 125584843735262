@import "../../styles/theme/variables";

.largeImageDiv{
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 0.5rem;
  @media screen and (min-width: 992px) {
    margin-top: 0.8rem;
  }
}
.largeImage{
  @media screen and (max-width: 768px) {
    width: -webkit-fill-available;
    height: unset;
  }
  @media screen and (min-width: 992px) {
    height: -webkit-fill-available;
    width: unset;
    // width: 100%;
  }
  @media screen and (min-width: 1280px) {
    // height: -webkit-fill-available;
    width: 100%;
    height: auto;
  }
}

@-moz-document url-prefix() {
  .largeImage{
    @media screen and (max-width: 768px) {
      width: -webkit-fill-available;
      height: 100%;
    }
    @media screen and (min-width: 992px) {
      height: 100%;
      width: fit-content;
    }
    @media screen and (min-width: 1280px) {
      height: auto;
      width: 100%;
    }
  }
}
