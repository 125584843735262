@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";
.parent {
  h4 {
    @include main-heading;
  }

  .gridSystem {
    margin: 47px 0;

    .boxName {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 249px;
      background: $white-color;
      cursor: pointer;
      border: 1px solid #00cfc5;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.25);
      padding: 20px;
      img {
        max-width: 100%;
      }
      @media (max-width: 767px) {
        min-height: 180px;
      }
      @media (max-width: 640px) {
        min-height: 140px;
      }
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // flex-direction: column;
      // width: 284px;
      // height: 249px;
      // // min-height: 197px;
      // background: $white-color;
      // cursor: pointer;
      // border: 1px solid #00cfc5;
      // border-radius: 7px;
      // box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.25);
      // position: relative;

      span {
        margin-left: 3px;
      }
      svg {
        margin-left: 3px;
      }
    }
    // .imageBox {
    //   // min-height: 90px;
    //   // display: flex;
    //   // align-items: center;
    //   // width: 90px;
    //   // justify-content: center;
    //   // background: #f3f3f3;
    //   // border-radius: 50%;
    //   // margin-bottom: 12px;
    //   // img {
    //   //    max-width: 46px;
    //   // }
    // }
    h4 {
      font-family: "Poppins-semi-bold";
      // font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      padding: 15px;

      text-align: center;

      color: #000000;
    }
  }
}
.createOwn {
  margin-top: 10px;
  p {
    font-family: "poppins-light";
    font-style: normal;

    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.025em;
    display: block;
    margin: 26px 0;
    color: $black-color;
  }
}
