@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,700&display=swap');
.reason_heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reason_heading_h4 {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-style: normal !important ;
  font-size: 28px !important ;
  line-height: 24px !important ;
  color: #000000 ;
}
.reason_heading_bold {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-size: 28px !important;
  line-height: 24px !important;
  color: #00cfc5 !important;
  text-decoration: underline;
}
.reason_heading_para {
  display: flex;
  justify-content: center;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  color: #757575  !important;
}
.content_two_img {
    display: flex;
    align-items: center;
    justify-content: center;
  /* padding-top: 90px; */
 
  width: 100%;
  height: 100%;
}
.main_box_grid{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
}
.main_box {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
  margin-left: 30px;
  /* background-color: #f4f4f4; */
  /* border: 1px solid #00cfc5; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; 
  border: 2px solid black;
  border-radius: 10px;
  position: relative;
  padding: 30px;
  padding-left: 70px;
  margin-bottom: 60px;
  /* height: 100%; */
}
.main_box:hover{
  border: 2px solid #00cfc5;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transition: all 0.5s ease-out;
}
.box_heading_header {
  font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    color: #000000;
}
.box_heading_para{
    font-family: 'Poppins'!important;
font-style: normal !important;
font-weight: 300 !important;
font-size: 16px !important;
line-height: 28px !important;
}
.image_box {
  position: absolute;
  top: 30%;
  left: -22px;
  width: 60px;
  height: 60px;
  background-color: #00cfc5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.pro_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}
@media only screen and (max-width: 440px) {
  .reason_heading_h4 ,.reason_heading_bold{
    font-size: 19px !important;
  }
  .box_heading_header{
    font-size: 18px !important;
  }
  .box_heading_para , .reason_heading_para{
    font-size: 16px;
  }
  .main_box{
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1194px) {
  .main_box{
    margin-bottom: 20px;
  }
}