@import "../../../styles/theme/variables";
.select {
  max-width: 350px;
  margin: auto;
  margin-top: 20px;
  @media screen and (max-width: 991px) {
    margin-top: 12px;
  }
  label {
    font-family: "poppins-light";
    font-style: normal;

    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    display: block;

    letter-spacing: 0.025em;

    color: $black-color;
    margin-bottom: 4px;
  }
  & ~ span {
    color: red;
    font-size: small;
  }
}
