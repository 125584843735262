@import "../../../styles/theme/variables";
.parent {
  .head {
    margin-bottom: 20px;
    h4 {
      font-family: "poppins-bold";
      font-style: normal;

      font-size: 32px;
      line-height: 40px;
      color: $black-color;
      @media (max-width: 640px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
  .buttonWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button {
      margin: 0 0 0 auto;
      max-width: 100%;
    }
    .return {
      a {
        display: flex;
        align-items: center;
        font-family: "poppins-light";

        font-size: 11px;
        color: #999999;
        svg {
          margin-right: 10px;
        }
        &:hover {
          color: $primary-color;
          svg path {
            stroke: $primary-color;
          }
        }
      }
    }
  }
  .form {
    // max-width: 1000px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    margin-top: 50px;
    @media (max-width: 767px) {
      margin-top: 20px;
    }
    h6 {
      font-family: "poppins-regular";
      font-size: 24px;
      line-height: 36px;
      @media (max-width: 640px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .createJobSelect {
      max-width: 100%;
      label {
        font-family: "poppins-bold";

        font-size: 14px;
        color: $black-color;
      }
      .react-select__control {
        border: 2px solid #898989;
      }
    }
    .createJobInp {
      min-width: unset;
      label {
        font-family: "poppins-regular";

        font-size: 14px;
        color: $black-color;
      }
      input {
        border: 2px solid #898989;
        border-radius: 3px;
      }
      textarea {
        border: 2px solid #898989;
        border-radius: 3px;
      }
    }
    .button {
      max-width: max-content;
      margin: 60px 0 0 auto;
      svg {
        margin-right: 10px;
      }
      button {
        font-family: "poppins-semi-bold";

        font-size: 12px;
      }
    }
    .job {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 30px;
      h6 {
        font-family: "poppins-regular";

        font-size: 24px;
        line-height: 36px;
      }
      svg {
        width: 20px;
        height: 25px;
      }
    }
  }
}

.parentBox {
  label {
    color: $black-color;
  }
}
