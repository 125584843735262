@import "../../../styles/theme/variables";

.parentInput {
  position: relative;
  margin: 15px 8px 0 0 !important;
  .input {
    min-width: 100% !important;
    label {
      font-family: "poppins-regular";
    }
  }
  @media (max-width: 640px) {
    margin: 0 !important;
  }
  span {
    position: absolute;
    left: 3px;
    display: block;
    color: $red-color;
    font-family: "poppins-semi-bold";
    font-size: 12px;
  }
}
.w_40 {
  width: 40%;
  @media (max-width: 640px) {
    width: 100%;
  }
}
.w_20 {
  width: 20%;
  @media (max-width: 640px) {
    width: 100%;
  }
}
.modalParent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  width: 100%;
  background-color: $white-color;
  border: none;
  padding: 40px;
  overflow-y: auto;
  // border-radius: 38px;
  @media screen and (max-width: 767px) {
    max-width: calc(100% - 24px);
    max-height: 400px;
  }

  @media screen and (max-width: 640px) {
    padding: 50px 20px 30px;
  }

  .modalwapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 575px) {
      align-items: flex-start;
    }

    .cross {
      position: absolute;
      width: 31px;
      height: 31px;
      top: -20px;
      right: -20px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid $black-color;
      &:hover {
        background: $primary-color;
        border: 1px solid $primary-color;
        svg path {
          color: $white-color;
          stroke: $white-color;
        }
      }
      svg {
        stroke: $black-color;
      }
      @media screen and (max-width: 640px) {
        top: -35px;
        right: -10px;
      }
    }
    .heading {
      font-family: "poppins-bold";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $black-color;
      text-align: center;
      margin-bottom: 20px;
    }
    .statusbox {
      form > div {
        gap: 16px;
        :last-child {
          margin-left: 0 !important;
        }
      }
      input {
        // border: 2px solid #898989;
        border-radius: 3px;
        // height: 54px;
        & + div {
          top: 18px;
        }
      }
      button {
        &:first-child {
          &:hover {
            background: #00cfc5;
            color: #fff;
          }
        }
        &:last-child {
          &:hover {
            border: 1px solid #00cfc5;
            background: #fff;
            color: #00cfc5;
          }
        }

        svg {
          color: white !important;
          max-height: auto;
        }
      }
      .button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // flex-wrap: wrap;
        min-width: initial;
        max-width: initial;
        margin: initial;
        width: 100%;
        button {
          max-width: 100px;
          max-height: 50px;
          padding: 10px;
          margin-right: 15px;
        }
        .editicon {
          margin-left: 10px;
        }
      }
    }
    button {
      padding: 7px 15px;
      min-width: 98px;
    }
  }
}
