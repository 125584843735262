@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";

.parent {
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    @media screen and (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
      h4 {
        margin-bottom: 20px;
        text-align: center;
        // order: 2;
      }
    }
    h4 {
      @include main-heading;
    }
    .buttonGroup {
      .button {
        max-width: 100%;
        svg {
          margin-right: 5px;
        }
        button {
          text-transform: capitalize;
          margin-top: 0;
          font-family: "poppins-regular";
          background-color: #00cfc5;
          color: white;
        }
      }
    }
  }
  .buttonTabs {
    button {
      font-family: "poppins-semi-bold";
      font-style: normal;

      font-size: 16px;
      text-transform: capitalize;
      line-height: 24px;
      /* identical to box height */

      letter-spacing: 0.025em;

      color: $black-color;
    }
  }
}
.select {
  margin-left: 12px;
  margin-right: 0;
  margin-top: 24px;
  max-width: 100%;
  @media (max-width: 599px) {
    margin-left: 0;
  }
}
.searchBar {
  background-color: #ffffff;
  border: 1px solid #afafaf;
  border-radius: 3px;
  width: 100%;
  font-family: "poppins-regular";
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.025em;
  color: #000;
  padding: 10px 35px 10px 10px;
  min-height: 41px;
  max-height: 41px;
  box-shadow: none;
  background-image: url(../../../assets/dashboard/activity/search.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 97%;

  &::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;

    /* Now your own custom styles */
    height: 14px;
    width: 14px;
    display: block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;

    /* icon size */
    background-size: 14px;
  }
}
.searchBar:focus,
.searchBar:focus-visible {
  border-color: #00cfc5;
  outline: 0;
}
