.bhBox {
  padding: 10px;
}

.dragcheck {
  position: relative;
}

.dragcheck .bhBox {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  padding: 0;
}
.dots.one,
.dots.three {
  background: #f4f7ff !important;
  left: -4px;
}
.dots.three {
  bottom: -6px;
}
.dots.four {
  background: #f4f7ff !important;
  right: -6px;
  bottom: -6px;
}
.dots.one {
  top: -4px;
}

.dots {
  background-color: #fff;
  border: 1px solid #00cfc5;
  border-radius: 50px;
  cursor: w-resize;
  height: 8px;
  position: absolute;
  width: 8px;
}

.closeicon {
  background: url(../interview/close.svg) no-repeat center #f4f7ff !important;
  background-size: contain;
  border: 1px solid #00cfc5;
  border-radius: 50px;
  cursor: pointer;
  height: 17px;
  padding: 5px;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 17px;
}
