@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";

.modelMail {
  h2 {
    @include heading_signup;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    font-family: "poppins-semi-bold";
  }
  p {
    @include description_signup;
    font-family: "poppins-light";
    text-align: center;
  }
  .buttonSection {
    padding: 10px 24px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .button {
      margin: 0;
      button {
        margin-top: 0;
      }
    }

    .button1 {
      margin: 0;
      button {
        margin-top: 0;
        // margin-left: -15px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .buttonSection {
    justify-content: flex-start;
  }
  .button1 {
    min-width: auto !important;
  }
  .button {
    min-width: auto !important;
    margin-left: 10px !important;
  }
}
