@import "../../../../styles/theme/variables";
.modalParent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  background-color: $white-color;
  border: none;
  padding: 40px;
  border-radius: 38px;

  .modalwapper {
    position: relative;

    .closeBtn {
      position: absolute;
      top: -10px;
      right: 0;
    }
    .heading {
      font-family: "poppins-bold";
      font-style: normal;

      font-size: 20px;
      line-height: 24px;
      color: $black-color;
      text-align: center;
    }
    .statusbox {
      display: flex;
      align-items: center;
      margin-top: 20px;
      h4 {
        font-family: "poppins-bold";
        font-style: normal;

        font-size: 16px;
        line-height: 24px;
        color: $black-color;
        margin-right: 5px;
      }
      p {
        font-style: normal;

        font-size: 14px;
        line-height: 24px;
        color: #898989;
      }
      .link {
        font-style: normal;

        font-size: 14px;
        line-height: 24px;
        text-decoration-line: underline;
        color: $primary-color;
      }
    }
  }
}
