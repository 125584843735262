.pikcolor {
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.black {
  background-color: black;
}

.blue {
  background-color: blue;
  box-shadow: none !important;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
  box-shadow: none !important;
}

.colorSection {
  display: flex;
  align-items: center;
}

.colors {
  display: flex;
}

.popupBoxOutter {
}

.popupBoxOutter .nav-tabs .nav-item.show .nav-link,
.popupBoxOutter .nav-tabs .nav-link.active {
  border: 0;
}

.popupBoxOutter .nav-tabs {
  border: 0;
}

.popupBoxOutter button.btn.btn-secondary {
  background-color: #fff;
  color: #00cfc5;
  border-radius: 5px;
  border-color: #00cfc5;
}

.popupBoxOutter button.btn.btn-secondary.bg-primary {
  background-color: #00cfc5 !important;
  color: #ffffff;
}

#myTabContent {
  margin-top: 0;
}

.menuSection {
  margin-bottom: 10px;
}

.popupBoxOutter .nav-tabs li {
  margin: 0 25px 0 0;
}

.popupBoxOutter .nav-tabs li:last-child {
  margin-right: 0;
}

.popupBoxOutter .nav-tabs .nav-link {
  padding: 0 0 5px 0 !important;
  display: block;
  line-height: 30px;
}

.popupBoxOutter .nav-tabs .nav-link {
  border-bottom: 2px solid transparent;
}

.popupBoxOutter .nav-tabs .nav-link.active {
  border-bottom: 2px solid #000 !important;
  color: #000;
}

.clearCanvas {
  margin-left: 10px;
}

.clearCanvas ul {
  display: flex;
  align-items: center;
}

.clearCanvas ul li {
  margin-right: 5px;
}

.clearCanvas ul li:last-child {
  margin-right: 0;
}

.clearCanvas ul li img {
  /* width: 20px; */
  width: 1.5rem;
}

.optionContainer.iconPopups label {
  font-size: 14px;
}

.optionContainer.iconPopups .form-check {
  display: flex;
  align-items: center;
}

.optionContainer.iconPopups .form-check {
  display: flex;
  align-items: center;
  margin: 0 0 5px -15px;
}

.optionContainer.iconPopups .button_box {
  margin: 15px 0;
}

.optionContainer.iconPopups input::placeholder,
.optionContainer.iconPopups textarea::placeholder,
.styles_optionContainer__D2nZW input::placeholder {
  font-size: 13px;
}

.styles_optionContainer__D2nZW input::placeholder {
  font-size: 14px;
}

.react-responsive-modal-modal {
  /* width: 400px; */
  width: auto;
  padding: 25px;
}

.optionContainer.iconPopups .button_box button {
  width: auto;
  border: 0;
  background: #00cfc5;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.styles_optionContainer__D2nZW {
  padding: 0 !important;
}

.styles_optionContainer__D2nZW label {
  font-size: 14px;
  margin-bottom: 10px;
}

.styles_optionContainer__D2nZW button {
  width: auto !important;
  border: 0 !important;
  background: #00cfc5 !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 8px 20px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.styles_optionContainer__D2nZW .text-center {
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0.6px;
  font-size: 18px;
}

.popupBoxOutter button.MuiButtonBase-root {
  margin: 0 5px !important;
  padding: 0 0 !important;
  line-height: normal;
  min-height: 38px;
  background-color: inherit !important;
  color: #00cfc5 !important;
  font-weight: 500 !important;
}

.container.content_box.mt-5
  .col-md-7.m-md-auto.mx-4
  .form_main_box.px-0.py-3
  button.btn.btn-primary.ml-3:hover,
.optionContainer.iconPopups .button_box button:hover,
.styles_optionContainer__D2nZW button:hover {
  background-color: #000;
}

.colorSection {
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.popuprowleft {
  display: flex;
}

#signTextInput {
  width: 100% !important;
}

#myTabContent label {
  margin: 15px 0 20px 0;
}

.nav-tabs .nav-link {
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.popupBoxOutter #myTabContent input {
  padding: 10px;
}

.popupBoxOutter .nav-tabs .nav-link:focus {
  outline: none;
}

.container.content_box.mt-5 .col-md-7.m-md-auto {
  border: 0 !important;
}

.container.content_box.mt-5
  .col-md-7.m-md-auto
  .filesList.form-control.file_list_main.mb-3 {
  height: inherit;
  border: 0;
  padding: 0;
  margin: 0;
}

.download_btn.mt-2.text-center {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.download_btn.mt-2.text-center button {
  margin: 0 !important;
}

.container.content_box.mt-5 .col-md-7.m-md-auto .form_main_box.px-0.py-3 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px !important;
  border-radius: 5px;
}
@media (max-width: 640px) {
  .container.content_box.mt-5 .col-md-7.m-md-auto .form_main_box.px-0.py-3 {
    padding: 0 !important;
    box-shadow: none;
  }
}

.container.content_box.mt-5
  .col-md-7.m-md-auto
  .form_main_box.px-0.py-3
  .form-label {
  font-weight: 700;
  margin-bottom: 12px;
}

.container.content_box.mt-5
  .col-md-7.m-md-auto
  .form_main_box.px-0.py-3
  .row
  div {
  font-size: 14px;
  margin-bottom: 8px;
}

.container.content_box.mt-5
  .col-md-7.m-md-auto
  .form_main_box.px-0.py-3
  .form-group {
  margin: 0;
}

.container.content_box.mt-5
  .col-md-7.m-md-auto
  .form_main_box.px-0.py-3
  label.form-label.file_title.text-capitalize {
  font-size: 18px;
}

.container.content_box.mt-5
  .col-md-7.m-md-auto
  .form_main_box.px-0.py-3
  button.btn.btn-primary.ml-3 {
  width: auto !important;
  border: 0 !important;
  background: #00cfc5 !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 8px 20px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.colorSection {
  flex-wrap: wrap;
}

.popuprowleft {
  /* width: 100%; */
  width: 'fit-content';
}

.fontStyleSection {
  margin: 10px 0 0 0 !important;
}

.fontStyleSection select {
  border: 1px solid #dfdfdf;
  padding: 5px 10px;
}

.colorSection {
  margin: 20px 0 20px 0;
}

.drawSection {
  margin-bottom: 20px !important;
}

.inputSignContainer input {
  border: 1px solid #dfdfdf;
}

.popuprowright {
  /* margin-top: 10px; */
  margin-top: 0;
}

.clearCanvas {
  margin: 0;
}

.clearCanvas ul {
  list-style: none;
}

.inputSignContainer {
  margin-top: 20px;
}

.sigCanvas {
  height: inherit;
}

.react-responsive-modal-modal form button {
  width: auto !important;
  border: 0 !important;

  background: #00cfc5 !important;

  color: #fff !important;

  border-radius: 5px !important;

  padding: 8px 20px;
  font-size: 14px !important;

  font-weight: 500 !important;
}
