@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";
.modelMail {
  :global {
    .MuiPaper-root.MuiPaper-elevation {
      @media screen and (min-width: 768px) {
        min-width: 700px !important;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .buttonSection {
      min-width: 600px;
      min-height: 300px;
      padding-bottom: 60px;
    }
  }
  h2 {
    @include heading_signup;
    text-align: center;
    font-size: 28px;
    margin-top: 16px;
    font-family: "poppins-semi-bold";
  }
  .buttonSection3 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      cursor: pointer;
    }
    h5 {
      font-family: "poppins-semi-bold";
      font-style: normal;

      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 96% */

      color: #000000;
    }
  }
}
.cross {
  position: absolute;
  width: 31px;
  height: 31px;
  top: 20px;
  right: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $black-color;
  &:hover {
    background: $primary-color;
    border: 1px solid $primary-color;
    svg path {
      color: $white-color;
      stroke: $white-color;
    }
  }
  svg {
    stroke: $black-color;
  }
}
