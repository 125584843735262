@import "../../../../styles/theme/variables";
@import "../../../../styles/theme/mixins";
.modelMail {
  h2 {
    @include heading_signup;
    text-align: left;
    font-size: 16px;
    font-family: "poppins-semi-bold";
  }
  p {
    @include description_signup;
    font-family: "poppins-light";
    text-align: left;
  }
  .buttonSection {
    padding: 10px 24px;
    padding-bottom: 20px;
    gap: 12px;
    .button {
      margin: 0;
      button {
        margin-top: 0;
      }
    }

    .button1 {
      margin: 0;
      button {
        margin-top: 0;
        margin-right: 15px;
      }
    }
  }
  h4 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
  }
  ul {
    list-style: disc;
    padding-left: 18px;
    margin-top: 12px;
    li {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 992px) {
  .buttonSection {
    justify-content: flex-start;
  }
  .button1 {
    min-width: auto !important;
  }
  .button {
    min-width: auto !important;
    margin-left: 10px !important;
  }
}
