.imageUploaderWrapper {
  margin-top: 19px;
  .crossImageBox {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #e5e5e5;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    .cross {
      position: absolute;
      right: -10px;
      top: -5px;
      opacity: 0.8;
      font-size: 20px;
      background: #00cfc5;
      border-radius: 100%;
      cursor: pointer;
      display: none;
      &:hover {
        opacity: 1;
      }
    }
    &:hover {
      .cross {
        display: block;
      }
    }
  }

  label {
    font-family: "poppins-regular";

    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    color: black;
    letter-spacing: 0.025em;
  }
  .imageUploader {
    margin-top: 4px;
    width: 100%;
    p {
      font-family: "poppins-light";
      font-style: normal;
      text-align: left;
      font-size: 14px;
      line-height: 21px;
      color: #9f9f9f;
      margin-top: 2px;
    }
    label {
      cursor: pointer;
      input {
        display: none;
      }
      .imageBox {
        background: url("../../../assets/imageUploader/imageUploader.png");
        min-width: 150px;
        height: 100px;
        background-repeat: no-repeat;
        background-size: contain;
        // background-position: center;
        // border-radius: 3px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-family: "poppins-light";
          font-style: normal;

          font-size: 14px;
          line-height: 21px;
          color: #ababab;
        }
      }
    }
  }
}
