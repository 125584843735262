@import "../../../../styles/theme/variables";

.googlebox {
  .interviewParrent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
      font-family: "poppins-bold";
      font-style: normal;

      font-size: 20px;
      line-height: 24px;
      /* or 120% */

      color: $black-color;
      text-align: center;
      margin-bottom: 30px;
    }
    .box-parent-textInput {
      position: relative;
      span {
        position: absolute;
        left: 3px;
        display: block;
        color: $red-color;
        font-family: "poppins-semi-bold";
        font-size: 12px;
      }
    }
    .interviewSelect {
      min-width: 100%;
      max-width: 100%;
      margin: 0 !important;
      margin-top: 19px !important;
      @media screen and (max-width: 576px) {
        min-width: 100%;
        max-width: 100%;
      }
    }
    .buttoninterview {
      margin: 0;
      // max-width: 320px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      button {
        max-width: 120px;
      }
    }
    .date-picker {
      @media screen and (max-width: 576px) {
        width: 100%;
        max-width: 100%;
      }
    }
    .react-datepicker-wrapper {
      min-width: 320px;
      @media screen and (max-width: 576px) {
        min-width: 100%;
        max-width: 100%;
      }
      input {
        background: $white-color;
        border: 1px solid #afafaf;
        border-radius: 3px;
        width: 100%;
        min-height: 41px;
        max-height: 41px;
        font-family: "poppins-light";
        font-style: normal;

        font-size: 12px;
        line-height: 18px;
        /* identical to box height */
        padding: 10px 16px;
        letter-spacing: 0.025em;

        color: $grey-color;
        &:focus-visible {
          outline: $primary-color auto 1px;
        }
        &::placeholder {
          font-family: "poppins-light";
          font-style: normal;

          font-size: 12px;
          line-height: 18px;
          /* identical to box height */

          letter-spacing: 0.025em;

          color: $grey-color;
        }
      }
    }
    .maxHeight {
      max-height: 41px !important;
    }
    .inputInterview {
      min-width: 100%;
      max-width: 100%;

      @media screen and (max-width: 576px) {
        min-width: 100%;
        max-width: 100%;
      }
      input {
        max-height: 41px;
        padding: 5px 33px;
      }
    }
  }
}
