@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,700&display=swap");
.blog_post_main_box {
  display: flex !important;
  justify-content: center !important;
}
.blog_post_1st_box {
  width: 100% !important;
}
.blog_post_main_sub_box {
  display: flex !important;
  justify-content: center !important;
  width: 70% !important;
}
.blog_post_heading_h4,
.description h4 {
  font-family: "poppins" !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  margin-bottom: 10px !important;
}
.blog_post_heading_para,
.description p {
  font-family: "poppins" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #757575 !important;
  margin-bottom: 15px;
}
.description blockquote {
  font-style: italic;
  padding-left: 12px;
  border-left: 6px solid #00cfc5;
}
.blog_post_main_img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 20px !important;
}
@media only screen and (max-width: 440px) {
  .blog_post_main_sub_box {
    width: 100% !important;
  }
}
