@import "../../../styles/theme/variables";
.parent {
  h4 {
    margin-bottom: 20px;
    font-family: "poppins-bold";
    font-style: normal;

    font-size: 28px;
    line-height: 24px;
    /* identical to box height, or 75% */

    color: $black-color;
  }
  .customBox {
    table {
      box-shadow: none;
    }
    .contentBox {
      td {
        font-family: "poppins-semi-bold";
        font-style: normal;

        font-size: 12px;
        line-height: 24px;
        box-shadow: none;
        /* identical to box height, or 200% */

        color: #6c6a6a;
      }
    }
    .headingBox {
      th {
        margin-top: 30px;
        font-family: "poppins-semi-bold";
        font-style: normal;

        font-size: 13px;
        line-height: 28px;
        /* identical to box height, or 215% */

        letter-spacing: 0.2px;
        text-transform: capitalize;

        color: #92929d;
      }
    }
  }
}
