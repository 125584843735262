/* .start_area {
  margin-top: 80px;
} */

.start_area .row {
  align-items: center;
}

.start_area button {
  width: auto;
  border: 0;
  background: #00cfc5;
  color: #fff;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
}

.start_area button:hover {
  background-color: #000;
}

.col {
  display: flex;
  align-items: center;
}

.start_button {
  display: flex;
  justify-content: flex-end;
}
/* .canvas .pdfImage {
  max-width: 100%;
} */
/* @media (max-width: 480px) {
  .canvas {
    max-width: 284px;
  }
} */
