@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";
.parent {
  h4 {
    @include main-heading;
  }
  .gridSystem {
    margin-top: 10px;
    .activityBox {
      margin-top: 50px;
      min-height: 129px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      background: $white-color;
      cursor: pointer;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      &:hover {
        transition: 0.7s;
        // background-image: url("../../images/PPC/hover.png");
        transform: scale(1.05);
      }
      img {
        max-width: 30px;
        max-height: 30px;
        min-height: 30px;
      }
      h4 {
        font-family: "poppins-regular";
        font-style: normal;

        font-size: 14px;
        line-height: 28px;

        margin: 5px 0;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        text-align: center;
        color: $black-color;
      }
      h5 {
        font-family: "poppins-light";
        font-style: normal;

        font-size: 13px;
        line-height: 28px;

        letter-spacing: 0.2px;
        text-transform: capitalize;
        text-align: center;
        color: $primary-color;
      }
    }
  }
  .tableBox {
    h5 {
      font-family: "poppins-semi-bold";
      font-style: normal;

      font-size: 15px;
      line-height: 28px;
      /* identical to box height, or 187% */

      letter-spacing: 0.2px;
      text-transform: capitalize;

      color: #000000;
      margin: 18px 0;
    }
  }
}
.hyperlink {
  a {
    word-break: break-all;
  }
}
