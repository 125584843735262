@import "../../../../styles/theme/variables";
@import "../../../../styles/theme/mixins";
.modelMail {
  .modelBox {
    max-width: 800px;
  }
  h2 {
    @include heading_signup;
    text-align: left;
    font-size: 16px;
    font-family: "poppins-semi-bold";
  }
  p {
    @include description_signup;
    font-family: "poppins-light";
    text-align: left;
  }
  .buttonSection {
    padding: 10px 24px;
    padding-bottom: 20px;
    .button {
      margin: 0;
      button {
        margin-top: 0;
      }
    }

    .button1 {
      margin: 0;
      button {
        margin-top: 0;
        margin-left: -15px;
      }
    }
  }
}

// .contactUs {
// margin-top: 23px;
// padding: 24px;
// .contactUsHeader {
//   h5 {
//     font-family: "poppins-semi-bold";
//     font-style: normal;

//     font-size: 20px;
//     line-height: 24px;
//     color: $black-color;
//   }
//   p {
//     margin-top: 10px;
//     font-size: 14px;
//   }
// }
.contactUsContent {
  .lastInpGrid {
    @media (max-width: 1200px) {
      padding-top: 0px;
    }
  }
  .tillbtn {
    margin-top: 55px;
    @media screen and (max-width: 1199px) {
      margin-top: 0px;
    }
  }
  .contactusInp {
    min-width: unset;
    margin-top: 0;
    input {
      border: 2px solid #898989;
      border-radius: 6px;
    }
    textarea {
      border: 2px solid #898989;
      border-radius: 6px;
    }
  }
  .btnSend {
    display: flex;
    justify-content: center;
    background-color: #00cfc5;
    margin: 25px auto;
    width: 130px;
    font-size: 12px;
  }
  .rightContent {
    margin-bottom: 50px;
    @media screen and (min-width: 992px) {
      padding-left: 50px;
    }
    h6 {
      font-family: "poppins-regular";

      font-size: 20px;
    }
    p {
      font-family: "poppins-light";
      max-width: 400px;

      font-size: 15px;
    }
    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}
// }

@media screen and (max-width: 992px) {
  .buttonSection {
    justify-content: flex-start;
  }
  .button1 {
    min-width: auto !important;
  }
  .button {
    min-width: auto !important;
    margin-left: 10px !important;
  }
}
