@import "../../../styles/theme/variables";

.industryModel {
  display: flex;
  align-items: center;
  justify-content: center;
  .boxModel {
    background: #ffffff;
    box-shadow: 0px 5px 17px 10px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    min-width: 624px;
    max-width: 624px;
    min-height: auto;
    position: relative;
    padding: 40px;

    @media screen and (max-width: 1200px) {
      min-width: 300px;
      max-width: 100%;
      margin: 0 20px;
      padding: 16px;
      min-height: 300px;
    }
    .cross {
      position: absolute;
      width: 31px;
      height: 31px;
      top: 20px;
      right: 20px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid $black-color;
      @media screen and (max-width: 575px) {
        top: 10px;
        right: 10px;
      }
      &:hover {
        background: $primary-color;
        border: 1px solid $primary-color;
        svg path {
          color: $white-color;
          stroke: $white-color;
        }
      }
      svg {
        stroke: $black-color;
        stroke-width: 1.5px;
      }
    }
  }
}
