@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,700&display=swap");
.contact_form_base_field {
  border: 2px solid #afafaf;
  width: 300px;
  height: 38px;
  padding-left: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.contact_form_base_field_upload {
  margin-top: 5px;
  /* border: 1px dashed  black; */
  /* padding: 30px; */
  /* width: 100% !important; */
}
.contact_form_base_field_btn {
  width: 100px;
  background-color: #00cfc5 !important;
}
.contact_main_page_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.con_main_box_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_main_page_header_h4 {
  font-family: "poppins" !important;
  font-weight: 600 !important;
  /* color: #00CFC5 !important; */
}
.contact_main_page_header_h4_bold {
  font-family: "poppins" !important;
  font-weight: 600 !important;
  color: #00cfc5 !important;
  text-decoration: underline;
}
.contact_main_page_header_h6 {
  font-family: "poppins" !important;
  font-weight: 600 !important;
  font-size: 24px !important;
}
.contact_main_page_header_para {
  font-family: "poppins" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #afafaf !important;
}
.attach_field_name {
  font-family: "poppins" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #afafaf !important;
}
.attach_field_name_checkbox {
  padding-top: 10px;
  font-family: "poppins" !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}
.contact_form_general_heading {
  margin-bottom: 30px !important;
  font-family: "poppins" !important;
  font-weight: 500 !important;
  font-size: 22px !important;
}
.contact_form_general_para {
  margin-bottom: 20px !important;
  font-family: "poppins" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.contact_form_general_heading_clr {
  color: #00cfc5;
}
.contact_form_general_heading_clr:hover {
  color: #00cfc5;
  text-decoration: underline;
}
.contact_form_base_field_email {
  border-bottom: 1px solid;
  margin-top: 10px;
  width: 300px;
}
.contact_form_base_field_img {
  width: 60px;
}
.contact_form_base_field_btn_box {
  /* display: flex;
    justify-content: center; */
}
.attach_btn_input {
  background-color: white !important;
  border: 1px solid #00cfc5 !important;
  color: #00cfc5 !important;
}

@media only screen and (max-width: 400px) {
  .contact_form_base_field_btn {
    margin-top: 20px !important;
    width: 100%;
  }
  .contact_form_base_field_email {
    width: 100%;
  }
}
.selectCreate .react-select__control {
  width: 100%;
  cursor: pointer;
  /* margin-top: -20px; */
}
/* .jobSalaryy {
  margin-top: 0;
} */
textarea {
  padding: 12px;
}
