@import "../../styles/theme/variables";

.not-found-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  .notFoundCard {
    padding: 40px;
    border-radius: 40px;
    text-align: center;
    @media screen and (max-width: 640px) {
      padding: 15px;
    }
    .title {
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
    }
    .info {
      max-width: 600px;
      margin: 20px auto 0;
      @media screen and (max-width: 992px) {
        max-width: 100%;
      }
    }
    .button-primary {
      max-width: max-content;
      button {
        border: 0;
        padding: 8px;
      }
    }
  }
  .glitch {
    position: relative;
    font-size: 120px;
    line-height: 140px;
    font-weight: 700;
    color: #00cfc5;
    @media screen and (max-width: 640px) {
      font-size: 60px;
      line-height: 70px;
    }
  }

  .glitch:before {
    content: attr(data-text);
    position: absolute;
    text-shadow: 1px 0 blue;
    top: 0;
    left: -4px;
    color: #00cfc5;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    -webkit-animation: glitch 3s infinite linear alternate-reverse;
    animation: glitch 3s infinite linear alternate-reverse;
    height: 100%;
    width: 100%;
  }

  .glitch:after {
    content: attr(data-text);
    position: absolute;
    text-shadow: -1px 0 red;
    top: 0;
    left: 4px;
    color: #00cfc5;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    -webkit-animation: glitch 2s infinite linear alternate-reverse;
    animation: glitch 2s infinite linear alternate-reverse;
    height: 100%;
    width: 100%;
  }

  @-webkit-keyframes glitch {
    0% {
      clip: rect(24px, 9999px, 136px, 0);
    }
    5% {
      clip: rect(142px, 9999px, 83px, 0);
    }
    10% {
      clip: rect(82px, 9999px, 37px, 0);
    }
    15% {
      clip: rect(51px, 9999px, 78px, 0);
    }
    20% {
      clip: rect(150px, 9999px, 39px, 0);
    }
    25% {
      clip: rect(66px, 9999px, 122px, 0);
    }
    30% {
      clip: rect(141px, 9999px, 33px, 0);
    }
    35% {
      clip: rect(126px, 9999px, 17px, 0);
    }
    40% {
      clip: rect(125px, 9999px, 124px, 0);
    }
    45% {
      clip: rect(34px, 9999px, 22px, 0);
    }
    50% {
      clip: rect(54px, 9999px, 71px, 0);
    }
    55% {
      clip: rect(34px, 9999px, 135px, 0);
    }
    60% {
      clip: rect(150px, 9999px, 98px, 0);
    }
    65% {
      clip: rect(26px, 9999px, 32px, 0);
    }
    70% {
      clip: rect(50px, 9999px, 2px, 0);
    }
    75% {
      clip: rect(144px, 9999px, 77px, 0);
    }
    80% {
      clip: rect(135px, 9999px, 53px, 0);
    }
    85% {
      clip: rect(131px, 9999px, 143px, 0);
    }
    90% {
      clip: rect(127px, 9999px, 133px, 0);
    }
    95% {
      clip: rect(24px, 9999px, 125px, 0);
    }
    100% {
      clip: rect(30px, 9999px, 147px, 0);
    }
  }

  @keyframes glitch {
    0% {
      clip: rect(24px, 9999px, 136px, 0);
    }
    5% {
      clip: rect(142px, 9999px, 83px, 0);
    }
    10% {
      clip: rect(82px, 9999px, 37px, 0);
    }
    15% {
      clip: rect(51px, 9999px, 78px, 0);
    }
    20% {
      clip: rect(150px, 9999px, 39px, 0);
    }
    25% {
      clip: rect(66px, 9999px, 122px, 0);
    }
    30% {
      clip: rect(141px, 9999px, 33px, 0);
    }
    35% {
      clip: rect(126px, 9999px, 17px, 0);
    }
    40% {
      clip: rect(125px, 9999px, 124px, 0);
    }
    45% {
      clip: rect(34px, 9999px, 22px, 0);
    }
    50% {
      clip: rect(54px, 9999px, 71px, 0);
    }
    55% {
      clip: rect(34px, 9999px, 135px, 0);
    }
    60% {
      clip: rect(150px, 9999px, 98px, 0);
    }
    65% {
      clip: rect(26px, 9999px, 32px, 0);
    }
    70% {
      clip: rect(50px, 9999px, 2px, 0);
    }
    75% {
      clip: rect(144px, 9999px, 77px, 0);
    }
    80% {
      clip: rect(135px, 9999px, 53px, 0);
    }
    85% {
      clip: rect(131px, 9999px, 143px, 0);
    }
    90% {
      clip: rect(127px, 9999px, 133px, 0);
    }
    95% {
      clip: rect(24px, 9999px, 125px, 0);
    }
    100% {
      clip: rect(30px, 9999px, 147px, 0);
    }
  }
}
