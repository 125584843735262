@import "../../../styles/theme/variables";
.candidateTable {
  margin-top: 20px;

  position: relative;
  margin-bottom: 40px;
  .customBox {
    table {
      box-shadow: none;
      border: 1px solid #e5e5e5;
      thead {
        border-bottom: 1px solid #e5e5e5;
      }
    }
    .contentBox {
      td {
        font-family: "poppins-light";
        font-style: normal;
        min-width: 100px;
        word-break: break-all;
        font-size: 12px;
        cursor: pointer;
        text-align: left;
        line-height: 21px;
        /* identical to box height */

        color: $black-color;
        // &:last-child {
        //   div {
        //     svg:nth-child(3) {
        //       display: none;
        //     }
        //     svg:nth-child(4) {
        //       display: none;
        //     }
        //   }
        // }
      }
    }
    .headingBox {
      th {
        font-family: "poppins-semi-bold";
        font-style: normal;

        font-size: 13px;
        line-height: 28px;
        text-align: left;
        /* identical to box height, or 215% */

        letter-spacing: 0.2px;
        text-transform: capitalize;

        color: #92929d;
      }
    }
  }
  .addTabel {
    font-family: "poppins-regular";
    font-style: normal;

    font-size: 20px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    line-height: 30px;
    letter-spacing: 0.025em;
    cursor: pointer;
    text-decoration-line: underline;
    svg {
      margin-right: 10px;
    }
    color: $primary-color;
  }
  .actionsBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      max-width: 20px;
      width: 16px;
      max-height: 20px;
      margin-left: 10px;
      font-size: 18px;
      color: #00cfc5;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
