.menu_drawer > div:nth-child(3){
    width: 50%;
}
.nav_toggle_btn{
    background-color: #00CFC5 !important;
}
.nav_link_home_mobile{
    display: flex;
    flex-direction: column;
    color: #00CFC5;
    padding: 12px 15px;
    border-bottom: 1px solid #dfdfdf;
}
.nav_link_home_mobile_btns{
    padding: 0;
}

.nav_link_home_mobile_btns button {
    display: block;
    width: 100%;
    margin: 0;
    padding: 12px 15px;
    border-bottom: 1px solid #dfdfdf;
    text-align: left;    
    border-radius: 0;
}

.nav_link_home_mobile_btns .MuiListItemText-root.css-tlelie-MuiListItemText-root {
    margin: 0; 
}

.nav_link_home_mobile_btns button.req_dem_btn {
    text-align: center;
}

@media (max-width: 640px){
    .menu_drawer > div:nth-child(3){
        width: 70%;
    }   
}