@import "../../../styles/theme/variables";

.parentInput {
  position: relative;
  .input {
    min-width: 100% !important;
    label {
      font-family: "poppins-regular";
    }
  }
  span {
    position: absolute;
    left: 3px;
    display: block;
    color: $red-color;
    font-family: "poppins-semi-bold";
    font-size: 12px;
  }
}
.modalParent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 900px;
  width: 100%;
  background-color: $white-color;
  border: none;
  padding: 40px;
  // border-radius: 38px;

  .modalwapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    .cross {
      position: absolute;
      width: 31px;
      height: 31px;
      top: -10px;
      right: 0px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid $black-color;
      &:hover {
        background: $primary-color;
        border: 1px solid $primary-color;
        svg path {
          color: $white-color;
          stroke: $white-color;
        }
      }
      svg {
        stroke: $black-color;
      }
    }
    .heading {
      font-family: "poppins-bold";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $black-color;
      text-align: center;
      margin-bottom: 20px;
    }
    .statusbox {
      input {
        // border: 2px solid #898989;
        border-radius: 3px;
        // height: 54px;
        & + div {
          top: 18px;
        }
      }
      button {
        &:hover {
          background: #00cfc5;
        }

        svg {
          color: white !important;
          max-height: auto;
        }
      }
      .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        min-width: initial;
        max-width: initial;
        margin: initial;
        width: 100%;
        button {
          max-width: 100px;
          max-height: 50px;
          padding: 10px;
        }
        .editicon {
          margin-left: 10px;
        }
      }
    }
  }
}
