@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,700&display=swap');
.level_heading_h4 {
    display: flex;
    justify-content: center;
    font-family: 'Poppins' !important;
    font-weight: 700 !important;
    font-style: normal !important;
    font-size: 28px !important;
    line-height: 24px !important;
    color: #000000 !important;
    /* padding-bottom: 12px; */
}
.level_heading_bold{
    font-family: 'Poppins' !important;
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 24px !important;
    color: #00CFC5 !important;
    text-decoration: underline;
    /* padding-bottom: 12px; */
    padding-right: 5px;
}
.contentone_level_para_heading{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 20px !important;
    line-height: 30px;
    color: #757575;
}
.contentfour_heading_search {
    border: 1px solid #00CFC5;
    position: relative;
    margin-top: 10px;
    width: 300px;
    background-color: white;
    padding: 10px;
    border-radius: 50px;
  }
  .contentfour_heading_btn_box{
    position: absolute;
    top: 0;
    right: 0;
  }
  .contentfour_heading_btn {
    
    background-color: #00cfc5 !important;
    border-radius: 50px 50px 50px 50px !important;
    /* border-radius: 50px; */
    width: 100%;
    height: 52px;
    
  }
.contentfour_box{
    /* border: 1px solid #00CFC5; */
    height: 100%;
    padding: 35px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid black;
}
.contentfour_box:hover {
    border: 2px solid #00cfc5;
    filter: none;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: all 0.5s ease-in-out;
  }
.contentfour_box:hover .content_four_img {
    filter: none;
    transition: all 0.5s ease-in-out;
  }
  .content_four_img:hover{
    filter: none;
    transition: all 0.5s ease-in-out;
}
  .icons_colorss{
    font-size: 60px;
    color: #00CFC5;

  }
.content_four_img{
    /* display: none; */
    filter: brightness(0%);
    color: #000000;
    width: 50px;
    padding-bottom: 15px;
    padding-bottom: 30px;
}
.content_four_img1{
    display: none;
    width: 50px;
    padding-bottom: 15px;
    padding-bottom: 30px;
}
.contentfour_box_h6_heading{
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #000000 !important;
}
.contentfour_box_para_heading{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px;
    color: #757575;
}
.hover_img{
    position: absolute;
    top: 0;
    /* bottom: 0; */
    left: 0;
    right: 0;
    /* height: 100%; */
    /* width: 100%; */
    opacity: 0;
    transition: .5s ease;
}
  @media only screen and (max-width: 440px) {
    .contentfour_heading_search{
        /* padding:20px; */
        height: 42px;
        padding: 5px;
    }
    .contentfour_heading_btn{
        height: 40px;
        width: 140px;
    }
    .level_heading_h4 , .level_heading_bold
    {
      font-size: 19px !important;
    }
    .contentfour_box_h6_heading{
        font-size: 18px !important;
    }
    .contentone_level_para_heading, .contentfour_box_para_heading{
        font-size: 16px;
    }
  }