.blog_box{
    background-image: url("../../../assets/Home-page/Mask-group.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentfive_blog_btn{
    background-color: #00CFC5 !important;
}
.blog_heading{
    color: white;
    font-weight: 500;
}
.blog_body{
    color: white;
}
.content_five_save_heading_box{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}
.content_five_save_heading{
    
    color: white;
    font-family: "poppins-bold" !important;
    font-style: normal !important;
    font-size: 28px !important;
    line-height: 34px !important;

}
.content_five_save_bodyheading{
    margin-left: 200px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 34px;
    color: #ffffff;
}
.content_five_save_bodyheading_btn{
    /* margin-left: 200px; */
    margin-top: 10px;
    color: white;
    text-decoration: underline;
}
.content_five_save_btn_box{
    display: flex;
    justify-content: flex-end;
   
    align-items: center;
}
.content_five_save_btn{
    display: flex;
    justify-content: flex-end;
    /* color: #00CFC5; */
    border: 1px solid white !important;
    color: #ffffff !important;

}
.contentfive_blog_heading{
    padding: 10px 0px ;
    font-family: "poppins-bold" !important;
    font-style: normal !important;
    font-size: 20px !important;
    line-height: 30px !important;
}
.contentfive_blog_heading_para{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #757575;
}
.blog_box_cont{
    display: flex;
    justify-content: center;
}
.contentfive_box {
  /* position: relative; */
  /* background-color: #F4F4F4; */
  /* border: 1px solid #00CFC5; */
  padding: 20px;
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 6px 6px 0px 0px;
}
.contentfive_img {
    width: 100%;
}
.contentfive_readmore_btn{
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    color: #00CFC5;
}
@media only screen and (max-width: 580px) {
    .blog_box{
        height: 140px;
    }
    .content_five_save_bodyheading {
      font-size: 12px !important;
    }
  }
  @media only screen and (max-width: 435px) {
    .contentfive_blog_heading{
        font-size: 17px !important;
    }
    .contentfive_blog_heading_para{
        font-size: 16px;
    }
    .blog_box{
        /* padding: 20px; */
        display: flex;
        justify-content: center;
        align-items: center !important;
        /* height: 140px; */
    }
    .content_five_save_heading{
        font-size: 18px !important;
    }
    .blog_box_cont{
        display: flex;
        justify-content: center;
    }
    .content_five_save_btn_box{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .content_five_save_btn{
        display: flex;
        justify-content: center;
        height: 25px;
    }
    .content_five_save_heading_box{
        justify-content: center;
        align-items: center;
    }
  }