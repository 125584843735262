@import "../../../styles/theme/variables";
.parent {
  .head {
    margin-bottom: 20px;
    h4 {
      font-family: "poppins-bold";
      font-style: normal;

      font-size: 32px;
      line-height: 24px;
      color: $black-color;
    }
  }
  .buttonWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button {
      margin: 0 0 0 auto;
      max-width: 100%;
    }
    .return {
      a {
        display: flex;
        align-items: center;
        font-family: "poppins-light";

        font-size: 11px;
        color: #999999;
        svg {
          margin-right: 10px;
        }
        &:hover {
          color: $primary-color;
          svg path {
            stroke: $primary-color;
          }
        }
      }
    }
  }
  .form {
    // max-width: 1000px;
    margin-top: 50px;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
    h6 {
      font-family: "poppins-regular";

      font-size: 24px;
      line-height: 36px;
    }
    .createJobSelect {
      max-width: 100%;
      label {
        font-family: "poppins-bold";

        font-size: 14px;
        color: #9f9f9f;
      }
      .react-select__control {
        border: 2px solid #898989;
      }
    }
    .createJobInp {
      min-width: unset;
      label {
        font-family: "poppins-bold";

        font-size: 14px;
        color: #9f9f9f;
      }
      input {
        border: 2px solid #898989;
        border-radius: 3px;
      }
      textarea {
        border: 2px solid #898989;
        border-radius: 3px;
      }
    }
    .button {
      max-width: max-content;
      min-width: auto;
      margin: 60px 0 0 auto;
      @media (max-width: 767px) {
        margin: 40px 0 0 auto;
      }
      svg {
        margin-right: 10px;
      }
      button {
        font-family: "poppins-semi-bold";

        font-size: 12px;
      }
    }
    .job {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 30px;
      h6 {
        font-family: "poppins-regular";

        font-size: 24px;
        line-height: 36px;
      }
      svg {
        width: 20px;
        height: 25px;
      }
    }
    .select-form__name {
      border-radius: 16px;
      box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
    }
  }
}

.input3Parent {
  margin-top: 19px;
  position: relative;
  min-height: 41px;
  label {
    font-family: "poppins-light";
    font-style: normal;

    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    display: block;

    letter-spacing: 0.025em;

    color: $black-color;
    margin-bottom: 4px;
    font-family: "poppins-bold";

    font-size: 14px;
    color: #9f9f9f;
  }
  .input3 {
    background: $white-color;
    border: 1px solid #afafaf;
    border-radius: 3px;
    width: 100%;
    position: relative;
    min-height: 41px;
    font-family: "poppins-light";
    font-style: normal;

    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    padding: 10px 16px !important;
    letter-spacing: 0.025em;
    border: 2px solid #898989;
    border-radius: 3px;
    &::-webkit-inner-spin-button {
      margin: 0;
    }
    &::-webkit-outer-spin-button {
      margin: 0;
    }
    color: $grey-color;
    &:focus-visible {
      outline: $primary-color auto 1px;
    }
    &::placeholder {
      font-family: "poppins-light";
      font-style: normal;

      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      letter-spacing: 0.025em;

      color: $grey-color;
    }
  }
}

.addDollar {
  position: relative;
  input {
    position: relative;
  }
  &::after {
    content: "$";
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(-50%, 0);
    font-family: "poppins-light";
    font-style: normal;
  }
}
