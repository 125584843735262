@import "../../../../../styles/theme/variables";
.industryModel {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  .boxModel {
    background: #ffffff;
    box-shadow: 0px 5px 17px 10px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    min-width: 624px;
    max-width: 624px;
    max-height: calc(100vh - 40%);
    overflow: auto;
    min-height: auto;

    position: relative;
    padding: 40px;

    @media screen and (max-width: 1200px) {
      min-width: 300px;
      max-width: 100%;
      margin: 0 20px;
      padding: 16px 12px;
      // min-height:300px;
    }

    .cross {
      position: absolute;
      width: 31px;
      height: 31px;
      top: -10px;

      right: 0px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      display: none;
      border: 1px solid $black-color;
      &:hover {
        background: $primary-color;
        border: 1px solid $primary-color;
        svg path {
          color: $white-color;
          stroke: $white-color;
        }
      }
      svg {
        stroke: $black-color;
      }
    }
    .heading {
      font-family: "poppins-bold";
      font-style: normal;

      font-size: 20px;
      line-height: 24px;
      color: $black-color;
      text-align: center;
      margin-bottom: 20px;
    }
    .statusbox {
      .formBox {
        @media screen and (max-width: 992px) {
          min-width: 100%;
        }
      }
      input {
        border: 2px solid #898989;
        border-radius: 3px;
        height: 54px;
        & + div {
          top: 18px;
        }
      }
      .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        flex-wrap: wrap;
        min-width: initial;
        max-width: initial;
        margin: initial;
        width: 100%;
        button {
          max-width: 126px;
          svg {
            margin-right: 8px;
          }
        }
        .editicon {
          margin-left: 10px;
        }
      }
    }
  }
}
