@import "../../../styles/theme/variables";

.interviewParrent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  input[type="time"]:not(:valid):before {
    content: "Time:";
    margin-right: 0.6em;
    color: #9d9d9d;
  }
  .parrentCandidateCloseInvite {
    position: relative;
    .candidateCloseInvite {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 0;
      & :hover {
        path {
          stroke: red;
        }
      }
    }
  }

  .addEmailCandidate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 19px 0;
    svg {
      cursor: pointer;
      margin-right: 7px;
    }
    p {
      margin: 0;
      font-family: "poppins-regular";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      letter-spacing: 0.025em;

      color: $black-color;
    }
  }

  h3 {
    font-family: "poppins-bold";
    font-style: normal;

    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    color: $black-color;
    text-align: center;
    margin-bottom: 30px;
  }
  .interviewSelect {
    min-width: 320px;
    max-width: 320px;
    position: relative;
    @media screen and (max-width: 576px) {
      min-width: 100%;
      max-width: 100%;
    }
  }
  .buttoninterview {
    margin: 0;
    max-width: 320px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      max-width: 120px;
      @media screen and (max-width: 576px) {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .interviewParrent .buttoninterview button {
    }
  }

  .date-picker {
    @media screen and (max-width: 576px) {
      width: 100%;
      max-width: 100%;
    }
    label {
      font-family: "poppins-light";
      font-style: normal;
      font-size: 15px;
      line-height: 22px;
      display: block;
      letter-spacing: 0.025em;
      color: #000000;
      margin-bottom: 4px;
    }
  }
  .react-datepicker-wrapper {
    min-width: 320px;
    @media screen and (max-width: 576px) {
      min-width: 100%;
      max-width: 100%;
    }
    input {
      background: $white-color;
      border: 1px solid #afafaf;
      border-radius: 3px;
      width: 100%;
      min-height: 41px;
      font-family: "poppins-light";
      font-style: normal;

      font-size: 12px;
      line-height: 18px;
      /* identical to box height */
      padding: 10px 16px;
      letter-spacing: 0.025em;

      color: $grey-color;
      &:focus-visible {
        outline: $primary-color auto 1px;
      }
      &::placeholder {
        font-family: "poppins-light";
        font-style: normal;

        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        letter-spacing: 0.025em;

        color: $grey-color;
      }
    }
  }
  .inputInterview {
    min-width: 320px;
    position: relative;
    max-width: 320px;
    @media screen and (max-width: 576px) {
      min-width: 100%;
      max-width: 100%;
    }
    input {
      padding: 5px 33px;
    }
  }
  select {
    background-color: #ffffff;
    border: 1px solid #afafaf;
    border-radius: 3px;
    width: 90%;
    font-family: "poppins-light";
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.025em;
    color: #9a9696;
    padding: 3px 16px;
    min-height: 41px;
    max-height: 41px;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(./../../../assets/jobs/icon-arrow-down.svg);
    background-repeat: no-repeat;
    background-position: 96% 10px;
    background-size: 20px;

    &:hover,
    &:focus,
    &:focus-visible {
      border-color: #00cfc5;
      border: 1px solid #00cfc5;
      outline: 0;
    }
    &::before {
      content: "";

      width: 16px;
      height: 16px;
      background-size: cover;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: rotate(90deg) translateY(-50%);
      display: block;
    }
  }
  label {
    font-family: "poppins-light";
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    display: block;
    letter-spacing: 0.025em;
    color: #000000;
    margin-bottom: 4px;
  }
}
