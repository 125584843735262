@import "../../../styles/theme/variables";
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");

.formCard {
  background: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
  padding: 40px;
  margin: 40px 0;

  label {
    display: block;
  }

  input[type="date"],
  .datePicker input {
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: -webkit-fill-available !important;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding-top: 1px;
    padding: 8.5px 14px;
    margin-bottom: 12px;
    background-image: url(../../../assets/sinner/calender.svg);
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 16px;
    cursor: pointer;

    &:focus {
      outline: 0;
      border-color: #00cfc5;
    }
  }

  input[type="date"]:before {
    content: attr();
    color: #aaa;
    margin-right: 0.5em;
    display: none;
  }

  p {
    margin-bottom: 12px;
    color: #a7a7a7;
  }

  .MuiFormControl-root {
    margin-bottom: 16px;
  }

  textarea {
    border-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    padding: 6px;
  }

  .styles_select__DO8Rh {
    margin: 0;
  }

  .slectLebel {
    > div {
      margin: 0;
    }

    margin: 0;

    label {
      display: none;
    }
  }

  .imageBox {
    width: 200px;
    height: 200px;
    // border: 1px solid #e5e5e5;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      height: 100%;
      // object-fit: cover;
      object-fit: contain;
    }
  }

  .signatureblock {
    height: 40px;
    border-bottom: 1px solid #e1e1e1;
    font-family: "Cedarville Cursive", cursive;
    font-size: 22px;
  }
}

.buttonTabs {
  button {
    font-family: "poppins-semi-bold";
    font-style: normal;

    font-size: 16px;
    text-transform: capitalize;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: 0.025em;

    color: $black-color;
  }
}

.buttons {
  display: flex;
  max-width: 100% !important;

  min-width: auto !important;
  align-items: center;
  margin: 0 !important;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }

  button {
    font-size: 12px;
    min-width: 100px;
  }

  & button:last-child {
    // margin-right: 10px;
    border: 0px;
  }

  & button:first-child {
    @media screen and (min-width: 992px) {
      margin-right: 10px;
    }
  }
}

.rejectedblock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;

  div {
    img {
      margin-right: 8px;
    }
  }
}

.fileUpload {
  display: inline-flex;
  max-width: 100% !important;
  padding: 8px 12px;
  min-width: auto !important;
  align-items: center;
  margin: 20px 0 0 !important;
  background: #00cfc5;
  color: #fff;
  cursor: pointer;

  label {
    margin: 0;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }
}

.checkboxgroup {
  margin-top: 20px;

  > label {
    margin-right: 24px;
  }

  label {
    margin-bottom: 0;
  }

  .customCheckBox {
    display: inline-block;

    margin-right: 16px;
  }
}
