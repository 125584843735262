@import "../../../styles/theme/variables";
@import "../../../styles/theme/mixins";

.sideBar {
  border: 1px solid #e5e5e5;
  height: calc(100vh - 218px);
  overflow-y: auto;
  padding: 8px;
  position: sticky;
  top: 0;

  svg {
    margin-right: 6px;
    font-size: 22px;
    color: #00cfc5;
    path {
      color: #00cfc5;
    }
  }
}

.formbox {
  height: 100%;
  border: 1px solid #e5e5e5;
  padding: 0 6px;
  label {
    font-family: "poppins-regular";
  }
  input[type="date"]:before {
    display: none;
  }
  .mt20 {
    margin-top: 20px;
  }
  .buttonGroup {
    display: flex;
    align-items: center;
    gap: 16px;
    .button {
      max-width: 100%;
      svg {
        margin-right: 5px;
      }
      button {
        text-transform: capitalize;
        margin-top: 0;
        font-family: "poppins-regular";
        background-color: #00cfc5;
        color: white;
        border-radius: 0;
        padding: 8px 12px;
      }
    }
  }
  .imgBox {
    max-width: 130px;
    // border: 1px solid #e5e5e5;
    border: 1px solid #000000;
    img {
      max-width: 100%;
    }
  }
}

.buttonGroup {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    justify-content: flex-start;
  }
  .button {
    max-width: 100%;
    button {
      text-transform: capitalize;
      margin-top: 0;
      font-family: "poppins-regular";
      background-color: #00cfc5;
      color: white;
    }
  }
  .secondaryBtn {
    background-color: transparent;
    color: #00cfc5;
    border: 1px solid #00cfc5;
  }
}

.select {
  max-width: 100%;
}
.fileUpload {
  display: inline-flex;
  max-width: 100% !important;
  padding: 9px 12px;
  min-width: auto !important;
  align-items: center;
  margin: 20px 0 0 !important;
  background: #00cfc5;
  color: #fff;

  input {
    width: auto;
    order: 3;
    &::-webkit-file-upload-button {
      display: none;
    }
  }
  cursor: pointer;
  label {
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      order: 1;
      margin-right: 8px;
    }
    span {
      order: 2;
      margin-right: 10px;
    }
  }
}
.checkboxgroup {
  margin-top: 20px;
  > label {
    margin-right: 24px;
  }
  label {
    margin-bottom: 0;
  }
  .customCheckBox {
    display: inline-block;
    margin-right: 16px;
  }
}
.ratingBox {
  margin-top: 20px;
  label {
    margin-bottom: 0;
    padding-right: 16px;
  }
  display: flex;
  align-items: center;
}
