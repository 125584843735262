@import "../../styles/theme/variables";
.header {
  min-height: 75px;
  padding: 20px 44px;
  @media screen and (max-width: 992px) {
    padding: 20px 15px;
  }
  @media screen and (max-width: 599px) {
    padding: 10px 12px;
  }
  .message {
    margin-right: 20px;
    @media screen and (max-width: 992px) {
      margin-right: 0;
    }
  }
  .notification {
    // margin-right: 20px;
    @media screen and (max-width: 992px) {
      margin-right: 0;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: "poppins-semi-bold";
    color: $primary-color;
  }

  .profileMenu {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    h5 {
      font-family: "poppins-regular";
      font-style: normal;

      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      display: flex;
      align-items: center;

      color: black;
      margin-right: 10px;
    }
    i {
      color: $black-color;
    }
  }
  .homebtn {
    font-size: 0;
    color: #2e3236;
    overflow: hidden;
    vertical-align: middle;
    fill: none;
  }
  @media screen and (max-width: 576px) {
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.css-o11vnp-MuiGrid-root {
      justify-content: flex-start;
    }
  }
  .toggleMenu {
    background: transparent;
    padding: 6px 8px;
    border-radius: 4px;
    border: 1px solid $primary-color;
    color: #fff;
    img {
      width: 20px;
    }
  }
}

.notification-list {
  list-style: none;
  li {
    font-size: 14px;
    white-space: pre-wrap;
  }
}
