.rightBox {
  position: relative;
  display: block;

  @media screen and (max-width: 992px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }

  .content {
    position: absolute;
    z-index: 12;
    top: 50px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    padding: 0 50px;
    h2 {
      font-family: "poppins-regular";
      font-style: normal;

      font-size: 36px;
      line-height: 36px;
      /* or 99% */

      text-align: center;

      color: #535353;
      @media screen and (max-width: 1280px) {
        font-size: 24px;
      }
    }
    h3 {
      font-family: "poppins-light";
      font-style: normal;

      font-size: 24px;
      text-align: center;
      line-height: 27px;
      /* identical to box height, or 112% */
      margin-top: 17px;
      color: #828181;
      @media screen and (max-width: 1280px) {
        font-size: 22px;
      }
    }
  }
  .imageBox {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      max-height: 100vh;
    }
  }
}
